<template>
  <div class="test">
    <!-- <img
      src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic29.photophoto.cn%2F20131206%2F0027010576888061_b.jpg&refer=http%3A%2F%2Fpic29.photophoto.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1633156219&t=9a412593cb9acea60cdf2f5dd4c162b6"
      alt=""
    /> -->
    <div class="container">
      <div class="circle circle-1"></div>
      <div class="circle circle-2"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Test",
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.test {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    width: 500px;
    height: 500px;
    // filter: blur(2px); 图片设置模糊值px
    // filter: brightness(70%); // 图片设置亮度0%全黑，100%无变化 100%以上比原图更亮。
    // filter: contrast(0%); //图片设置对比度
  }
  .container {
    margin: 50px auto;
    height: 140px;
    width: 400px;
    background: #fff; //给融合元素的父元素设置背景色
    display: flex;
    align-items: center;
    justify-content: center;
    filter: contrast(20); //给融合元素的父元素设置contrast
  }
  .circle {
    border-radius: 50%;
    position: absolute;
    filter: blur(20px); //给融合元素设置blur
  }
  .circle-1 {
    height: 90px;
    width: 90px;
    background: #03a9f4;
    transform: translate(50px);
    animation: 2s moving linear infinite alternate-reverse;
  }
  .circle-2 {
    height: 60px;
    width: 60px;
    background: #0000ff;
    transform: translate(10px);
    animation: 2s moving linear infinite alternate;
  }
  @keyframes moving {
    //两个元素的移动
    0% {
      transform: translate(50px);
    }
    100% {
      transform: translate(-50px);
    }
  }
}
</style>
